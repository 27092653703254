<template>
  <div class="contenta">
    <img class="img-back" src="@/assets/images/successBack.png" alt="" />
    <div class="gont1">绑定成功</div>
    <div class="gont2">报名结果通知将通过公众号微信推送给你</div>
    <div class="btn" @click="jumpHome">返回首页</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    jumpHome() {
      window.location.href = "/logsPage";
    },
  },
};
</script>

<style scoped>
.contenta {
  padding-top: 80px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.img-back {
  width: 140px;
  height: 140px;
}
.gont1 {
  font-size: 16px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.btn {
  width: 50%;
  height: 48px;
  background: #e01811;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
  margin: 32px;
}
</style>